const spriteCode = `
    import math
    import random
    import time
 
    class Sprite:
        def __init__(self):
            self.x = 0
            self.y = 0
            self.angle = 0
            self.size = 0
            self.direction = 0
            self.secs = 0
            self.costume_name = None
            self.backdrop_name = None

        def move(self, distance):
            self.x += distance * math.cos(math.radians(self.angle))
            self.y += distance * math.sin(math.radians(self.angle))
            
            if self.x >= 260:
                self.angle = 180 - self.angle
                self.x = 260  
            return f"Moved to ({self.x}, {self.y})"

        def right(self, angle):
            self.angle += angle
            return f"Turned to {self.angle} degrees"

        def left(self, angle):
            self.angle -= angle
            return f"Turned to {self.angle} degrees"

        def gotoxy(self, x, y):
            self.x = x
            self.y = y
            return f"Moved to ({self.x}, {self.y})"

        def say(self, message):
            self.websocket.send(f"sprite.say('{message}')")
            return f"Sprite says: {message}"

        def goto(self):
            self.x = random.randint(0, 260)  # assuming the canvas is 260x260
            self.y = random.randint(0, 260)
            return f"Moved to ({self.x}, {self.y})"

        def setx(self, x):
            self.x = x 
            return f"Set X position ({self.x})"

        def sety(self, y):
            self.y = y
            return f"Set Y position ({self.y})"

        def changex(self, distance):
            self.x += distance * math.cos(math.radians(self.angle))
            return f"Change X position ({self.x})"

        def changey(self, distance):
            self.y += distance * math.sin(math.radians(self.angle))
            return f"Change X position ({self.y})"

        def setdirection(self, angle):
            self.angle = angle
            return f"Turned to {self.angle} degrees"

        def setsize(self, size):
            self.size = size
            return f"Set size to {self.size}"

        def changesize(self, size):
            self.size = size
            return f"Set size to {self.size}"

        def say(self, message):
            return f"{(message)}"

        def sayforsecs(self, message, secs):
            self.secs = secs
            return f"sayforsecs('{message}', {secs})"

        def show(self):
            return "Show sprite"

        def hide(self):
            return "Hide sprite"

        def think(self, message):
            return f"{message}"

        def thinkforsecs(self, message, secs):
            self.secs = secs
            return f"thinkforsecs('{message}', {secs})"

        def input(self, question):
            return f"INPUT({question})"

        def switchcostume(self, costume_name):
            new_costume_name = costume_name
            self.costume_name = new_costume_name
            return f"Switched to costume '{new_costume_name}'"

        def nextcostume(self):
            return f"Switching to a next costume"

        def switchbackdrop(self, backdrop_name):
            new_backdrop_name = backdrop_name
            self.backdrop_name = new_backdrop_name
            return f"Switched to backdrop '{new_backdrop_name}'"

        def nextbackdrop(self):
            return f"Switching to a next costume"
        
        def iskeypresed(self, key_name):
            self.key_name = key_name
            return f"pressed a {key_name} key"

    sprite = Sprite()

`;

export default spriteCode;