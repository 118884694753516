import bindAll from 'lodash.bindall'
import PropTypes from 'prop-types'
import React from 'react'
import Renderer from 'scratch-render'
import VM from 'blockzie-vm'
import { connect } from 'react-redux'
import costumeJson from '../lib/libraries/costumes.json'
import backdropJson from '../lib/libraries/backdrops.json'

// import Scratch3LooksBlocks from 'blockzie-vm'
// import RenderedTarget from '../../node_modules/blockzie-vm/src/sprites/rendered-target';

import { STAGE_DISPLAY_SIZES } from '../lib/layout-constants'
import { getEventXY } from '../lib/touch-utils'
import VideoProvider from '../lib/video/video-provider'
import { BitmapAdapter as V2BitmapAdapter } from 'scratch-svg-renderer'
import StageComponent from '../components/stage/stage.jsx'
import Scratch3LooksBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_looks.js'
import Scratch3SensingBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_sensing.js';

import {
    activateColorPicker,
    deactivateColorPicker
} from '../reducers/color-picker';

const colorPickerRadius = 20;
const dragThreshold = 3; // Same as the block drag threshold

class Stage extends React.Component {
    constructor(props) {
        super(props);
        this.looksBlocks = new Scratch3LooksBlocks(props.vm); // assuming runtime is defined
        this.sensingblocks = new Scratch3SensingBlocks(props.vm);
        this.count = 0;
        // this.runtime = runtime;
        bindAll(this, [
            'attachMouseEvents',
            'cancelMouseDownTimeout',
            'detachMouseEvents',
            'handleDoubleClick',
            'handleQuestionAnswered',
            'onMouseUp',
            'onMouseMove',
            'onMouseDown',
            'onStartDrag',
            'onStopDrag',
            'onWheel',
            'updateRect',
            'questionListener',
            'setDragCanvas',
            'clearDragCanvas',
            'drawDragCanvas',
            'positionDragCanvas'
        ]);
        this.state = {
            mouseDownTimeoutId: null,
            mouseDownPosition: null,
            isDragging: false,
            dragOffset: null,
            dragId: null,
            colorInfo: null,
            question: null,
        };
        if (this.props.vm.renderer) {
            this.renderer = this.props.vm.renderer;
            this.canvas = this.renderer.canvas;
        } else {
            this.canvas = document.createElement('canvas');
            this.renderer = new Renderer(this.canvas);
            this.props.vm.attachRenderer(this.renderer);

            // Only attach a video provider once because it is stateful
            this.props.vm.setVideoProvider(new VideoProvider());

            // Calling draw a single time before any project is loaded just makes
            // the canvas white instead of solid black–needed because it is not
            // possible to use CSS to style the canvas to have a different
            // default color
            this.props.vm.renderer.draw();
        }
        this.props.vm.attachV2BitmapAdapter(new V2BitmapAdapter());
    }

    componentDidMount() {
        this.attachRectEvents();
        this.attachMouseEvents(this.canvas);
        this.updateRect();
        this.props.vm.runtime.addListener('QUESTION', this.questionListener);

        
        // const executeLoop = (conditionFn, actionFn, loopType, times) => {
        //     let count = 0;
        
        //     const loop = () => {
        //         if (conditionFn()) {
        //             actionFn();  // Move the sprite
        //             count++;     // Increment the loop counter
        
        //             // Stop the loop after it has repeated `times` times
        //             if (loopType === 'repeat' && count >= times) {
        //                 return;
        //             }
        
        //             // Use setTimeout for controlling the timing of the loop
        //             setTimeout(loop, 500);  // Delay before the next loop iteration (500ms)
        //         }
        //     };
        
        //     loop();  // Start the loop
        // };
        
        // // Repeat for a set number of times (for loop equivalent)
        // window.addEventListener('spriteRepeat', (event) => {
        //     const times = event.detail.times;   // Number of times to repeat (e.g., 5 for range(5))
        //     const moveAmount = event.detail.amount;  // Amount to move the sprite (e.g., 10 units)
            
        //     let count = 0;  // Initialize count for the loop
            
        //     // Ensure the loop moves the sprite exactly `times` times
        //     executeLoop(
        //         () => count < times,   // Condition to repeat
        //         () => {
        //             const result = moveAmount;
        //             this.props.vm.postSpriteInfo({ x: result * (count + 1) });  // Move the sprite
        //             console.log('x =', result * (count + 1));  // Log the position for debugging
        //             count++;  // Increment the count after each movement
        //         },
        //         'repeat',
        //         times  // Pass the number of repetitions
        //     );
        // });

        // window.dispatchEvent(new CustomEvent('spriteRepeat', { 
        //     detail: { times: 5, amount: 10 } 
        // }));
        
        

        
    
        // // While loop (repeat while a condition is true)
        // window.addEventListener('spriteWhile', (event) => {
        //     const condition = event.detail.condition;  // Condition function passed from Python
        //     executeLoop(
        //         condition,  // Condition function
        //         () => {
        //             const result = event.detail.amount;
        //             this.props.vm.postSpriteInfo({ x: result * this.count });
        //             this.count++;
        //         },
        //         'while'
        //     );
        // });
    
        // // If condition
        // window.addEventListener('spriteIf', (event) => {
        //     const condition = event.detail.condition;
        //     if (condition()) {
        //         const result = event.detail.amount;
        //         this.props.vm.postSpriteInfo({ x: result * this.count });
        //         this.count++;
        //     }
        // });
    
        // // Now, add sprite-specific commands and loops that execute sprite actions repeatedly
        // window.addEventListener('spriteMove', (event) => {
        //     const result = event.detail;
        //     const currentX = this.count * result;
        //     const x = Math.min(currentX, 260);
        //     this.props.vm.postSpriteInfo({ x });
        //     console.log('x = ', x);
        //     if (x === 260) {
        //         this.count = -this.count;
        //     } else {
        //         this.count++;
        //     }
        // });
    
        // window.addEventListener('spriteRight', (event) => {
        //     const angle = event.detail;
        //     this.props.vm.postSpriteInfo({ direction: angle + this.count * angle });
        //     this.count++;
        // });
    
        // window.addEventListener('spriteLeft', (event) => {
        //     const angle = event.detail;
        //     this.props.vm.postSpriteInfo({ direction: angle - this.count * angle });
        //     this.count++;
        // });
    
        // // Add other sprite commands as needed...
    
        // // Repeat Until (exit loop when a condition becomes true)
        // window.addEventListener('spriteRepeatUntil', (event) => {
        //     const conditionFn = event.detail.condition;
        //     executeLoop(
        //         () => !conditionFn(),  // Repeat until the condition becomes true
        //         () => {
        //             const result = event.detail.amount;
        //             this.props.vm.postSpriteInfo({ x: result * this.count });
        //             this.count++;
        //         },
        //         'repeatUntil'
        //     );
        // });
    
        // // Infinite loop (forever)
        // window.addEventListener('spriteForever', () => {
        //     const loop = () => {
        //         const x = Math.random() * 260;
        //         const y = Math.random() * 260;
        //         this.props.vm.postSpriteInfo({ x, y });
        //         requestAnimationFrame(loop);  // Continue the infinite loop
        //     };
    
        //     loop();
        // });

        window.addEventListener('spriteMove', (event) => {
            const result = event.detail;
            const currentX = this.count * result;
            const x = Math.min(currentX, 260);
            this.props.vm.postSpriteInfo({ x });
            console.log('x = ',x);
            if (x == 260) {
                this.count = -this.count;
            } else {
                this.count++;
            }
        });

        window.addEventListener('spriteRight', (event) => {
            const angle = event.detail;
            this.props.vm.postSpriteInfo({ direction: angle + this.count * angle });
            this.count++;
        });

        window.addEventListener('spriteLeft', (event) => {
            const angle = event.detail;
            this.props.vm.postSpriteInfo({ direction: angle - this.count * angle });
            this.count++;
        });

        window.addEventListener('spriteGotoxy', (event) => {
            const result = event.detail;
            const x = result.x;
            const y = result.y;
            this.props.vm.postSpriteInfo({ x, y });
            this.count++;
        });

        window.addEventListener('spriteGoto', () => {
            const x = Math.floor(Math.random() * 260);
            const y = Math.floor(Math.random() * 260);
            this.props.vm.postSpriteInfo({ x, y });
        });

        window.addEventListener('spriteSetx', (event) => {
            const result = event.detail;
            const x = Math.min(result, 260); // Limit x to 260
            this.props.vm.postSpriteInfo({ x });
        });;

        window.addEventListener('spriteSety', (event) => {
            const result = event.detail;
            const y = Math.min(result, 260); // Limit x to 260
            this.props.vm.postSpriteInfo({ y });
        });

        window.addEventListener('spriteChangex', (event) => {
            const result = event.detail;
            const x = Math.min(this.count * result, 260); // Limit x to 260
            this.props.vm.postSpriteInfo({ x });
            this.count++;
        });

        window.addEventListener('spriteChangey', (event) => {
            const result = event.detail;
            const y = Math.min(this.count * result, 260); // Limit x to 260
            this.props.vm.postSpriteInfo({ y });
            this.count++;
        });

        window.addEventListener('spriteSetdirection', (event) => {
            const angle = event.detail;
            this.props.vm.postSpriteInfo({ direction: angle });
        });

        window.addEventListener('spriteSetsize', (event) => {
            const result = event.detail;
            this.props.vm.postSpriteInfo({ size: result });
        });

        window.addEventListener('spriteSize', (event) => {
            const size = event.detail;
            this.props.vm.postSpriteInfo({ size: size });
        });

        window.addEventListener('spriteChangesize', (event) => {
            const result = event.detail;
            const size = this.count * result;
            this.props.vm.postSpriteInfo({ size: size });
            this.count++;
        });

        window.addEventListener('spriteShow', () => {
            this.props.vm.postSpriteInfo({ visible: true });
        });

        window.addEventListener('spriteHide', () => {
            this.props.vm.postSpriteInfo({ visible: false });
        });

        window.addEventListener('spriteSay', (event) => {
            const message = event.detail;
            console.log(message);
            const target = this.props.vm.runtime.targets[1];
            console.log(target);
            const util = { target: target };
            this.looksBlocks.say({ MESSAGE: message }, util);
        });

        window.addEventListener('spriteSayForSecs', (event) => {
            const message = event.detail.message;
            const seconds = event.detail.seconds;
            console.log(`Message: ${message}, Seconds: ${seconds}`);
            const target = this.props.vm.runtime.targets[1];
            console.log(target);
            const util = { target: target };
            this.looksBlocks.sayforsecs({ SECS: seconds, MESSAGE: message }, util);
        });

        window.addEventListener('spriteThink', (event) => {
            const message = event.detail;
            console.log(message);
            const target = this.props.vm.runtime.targets[1];
            console.log(target);
            const util = { target: target };
            this.looksBlocks.think({ MESSAGE: message }, util);
        });

        window.addEventListener('spriteThinkForSecs', (event) => {
            const message = event.detail.message;
            const seconds = event.detail.seconds;
            console.log(`Message: ${message}, Seconds: ${seconds}`);
            const target = this.props.vm.runtime.targets[1];
            console.log(target);
            const util = { target: target };
            this.looksBlocks.thinkforsecs({ SECS: seconds, MESSAGE: message }, util);
        });

        window.addEventListener('spriteInput', (event) => {
            const question = event.detail;
            console.log(question);
            const target = this.props.vm.runtime.targets[1];
            console.log(target);
            const util = { target: target };
            this.questionListener(question);
            // this.sensingblocks._clearTargetQuestions({ stopTarget: target });

            this.sensingblocks.askAndWait({ QUESTION: question }, util, (answer) => {
                this.setState({ message: answer });
            this.props.vm.runtime.emit('ANSWER', answer);
            })
        });

        // window.addEventListener('spriteSwitchCostume', (event) => {
        //     const costumeName = event.detail.toLowerCase();
        //     console.log(costumeName);
            
        //     let matchingCostume= [];
        //     for (let i = 0; i < costumeJson.length; i++) {
        //         const costumeBaseName = costumeJson[i].name.replace(/-.*/, ''); // remove suffixes (e.g., "-a", "-b", etc.)
        //         const clientBaseName = costumeName.replace(/-.*/, ''); // remove suffixes (e.g., "-a", "-b", etc.)
        //         if (costumeBaseName.toLowerCase() === clientBaseName) {
        //             matchingCostume.push(costumeJson[i].name);
        //         }
        //     }
        //     // for (let i = 0; i < costumeJson.length; i++) {
        //     //     if (costumeJson[i].name.toLowerCase() === costumeName) {
        //     //     // console.log('matching try')
        //     //     matchingCostume.push(costumeJson[i].name);
        //     //     // matchingCostume = costumeJson[i].name;
        //     //     break;
        //     //     }
        //     // }
        //     let matchingIndex;
        //     for(let i = 0; i <= matchingCostume.length; i++){
        //         if(matchingCostume[i].toLowerCase() === costumeName){
        //             matchingIndex = i;
        //             break;
        //         }
        //     }
        //     console.log(matchingIndex);
        //     console.log(matchingCostume);
        //     if (matchingIndex) {
        //         const target = this.props.vm.runtime.targets[1];
        //         console.log(target);
        //         // const costumeIndex = target.getCostumeIndexByName(requestedCostume.toString());

        //         const util = { target: target };
        //         this.looksBlocks.switchCostume({ COSTUME: matchingCostume}, util);
        //         // this.looksBlocks._setCostume({target : target, requestCostume: matchingIndex, costumeIndex: matchingIndex}, true)
        //     } else {
        //         console.log(`No matching costume found for ${costumeName}`);
        //     }
        // });
        window.addEventListener('spriteSwitchCostume', (event) => {
            const costumeName = event.detail.trim().toLowerCase();  // Normalize the costume name
            console.log('Requested costume:', costumeName);
            
            let matchingCostume = [];
            for (let i = 0; i < costumeJson.length; i++) {
                const costumeBaseName = costumeJson[i].name.replace(/-.*/, '').trim().toLowerCase();  // Normalize name
                const clientBaseName = costumeName.replace(/-.*/, '').trim().toLowerCase();  // Normalize name
                if (costumeBaseName === clientBaseName) {
                    matchingCostume.push(costumeJson[i].name);
                }
            }
        
            let matchingIndex = -1;
            for (let i = 0; i < matchingCostume.length; i++) {
                if (matchingCostume[i].trim().toLowerCase() === costumeName) {
                    matchingIndex = i;
                    break;
                }
            }
        
            console.log('Matching Index:', matchingIndex);
            console.log('Matching Costumes:', matchingCostume);
        
            if (matchingIndex !== -1) {
                const target = this.props.vm.runtime.targets[1];  // Assuming [1] is the correct sprite target
                console.log('Target sprite:', target);
        
                // Log all available costumes
                console.log('Available Costumes:', target.getCostumes());
        
                const selectedCostume = matchingCostume[matchingIndex].trim();
                console.log('Switching to costume:', selectedCostume);
        
                // Find the costume index by name
                const costumeIndex = matchingIndex;
                console.log('Costume index found:', costumeIndex);
        
                if (costumeIndex !== -1) {
                    target.setCostume(costumeIndex);
                    console.log(`Costume switched to: ${selectedCostume}`);
                } else {
                    console.log(`Costume "${selectedCostume}" not found.`);
                }
            } else {
                console.log(`No matching costume found for ${costumeName}`);   
            }
        });
        
        window.addEventListener('spriteNextCostume', () => {
            // Access the correct sprite target, assuming [1] is the correct index
            const target = this.props.vm.runtime.targets[1];
        
            // Check if the target is valid
            if (target && target.sprite) {
                // Access costumes array directly from target or sprite object
                const costumes = target.sprite.costumes_;  // Try accessing costumes
        
                // Logging to check the target and sprite structure
                console.log('Target:', target);
                console.log('Costumes:', costumes);
        
                // Try accessing the current costume index from target
                const currentCostumeIndex = target.currentCostume;  // Alternative way to get current costume index
        
                console.log(`Current costume index: ${currentCostumeIndex}`);
                console.log(`Costumes available:`, costumes);
        
                // Calculate the next costume index
                const nextCostumeIndex = (currentCostumeIndex + 1) % costumes.length;
        
                // Set the next costume using index
                target.setCostume(nextCostumeIndex);
        
                console.log(`Costume switched to index: ${nextCostumeIndex}`);
                console.log(`New costume: `, costumes[nextCostumeIndex]);
            } else {
                console.error('Target sprite not found or invalid.');
            }
        });
        
        window.addEventListener('spriteSwitchBackdrop', (event) => {
            const backdropName = event.detail.trim();  // Get the backdrop name from Python
            console.log('Requested backdrop:', backdropName);
        
            // Get the VM (Virtual Machine) instance
            const vm = this.props.vm;
        
            if (!vm) {
                console.error('VM not available');
                return;
            }
        
            // Access the stage directly
            const stage = vm.runtime.targets.find(target => target.isStage);
        
            if (!stage) {
                console.error('Stage not found');
                return;
            }
        
            console.log('Stage found:', stage);
            console.log('Available Backdrops:', stage.getCostumes().map(c => c.name));
        
            // Get the backdrop index by name
            const backdropIndex = stage.getCostumeIndexByName(backdropName);
        
            if (backdropIndex !== -1) {
                // Valid backdrop name
                stage.setCostume(backdropIndex);
                console.log('Backdrop switched to:', backdropName);
            } else {
                console.warn(`Backdrop "${backdropName}" not found.`);
            }
        
            // Trigger event for backdrop switch if needed
            if (this.runtime) {
                this.runtime.startHats('event_whenbackdropswitchesto', { BACKDROP: backdropName });
            }
        });

        window.addEventListener('spriteNextBackdrop', () => {
               
            
                // const backdropName = event.detail.trim();  // Get the backdrop name from Python
                // console.log('Requested backdrop:', backdropName);
            
                // Get the VM (Virtual Machine) instance
                const vm = this.props.vm;
            
                if (!vm) {
                    console.error('VM not available');
                    return;
                }
            
                // Access the stage directly
                const stage = vm.runtime.targets.find(target => target.isStage);
            
                if (!stage) {
                    console.error('Stage not found');
                    return;
                }
            
                console.log('Stage found:', stage);
                console.log('Available Backdrops:', stage.getCostumes().map(c => c.name));
            
               
                    // Switch to the next backdrop
                    const currentBackdropIndex = stage.currentCostume;
                    const numBackdrops = stage.getCostumes().length;
                    const nextBackdropIndex = (currentBackdropIndex + 1) % numBackdrops;
            
                    stage.setCostume(nextBackdropIndex);
                    console.log('Backdrop switched to next:', stage.getCostumes()[nextBackdropIndex].name);
                
                    // Handle specific backdrop name
                    const backdropIndex = stage.getCostumeIndexByName();
            
                    if (backdropIndex !== -1) {
                        // Valid backdrop name
                        stage.setCostume(backdropIndex);
                        // console.log('Backdrop switched to:', backdropName);
                    } else {
                        console.warn(`Backdrop not found.`);
                    }
            
                // Trigger event for backdrop switch if needed
                if (this.runtime) {
                    this.runtime.startHats('event_whenbackdropswitchesto', { BACKDROP: backdropName });
                }
            });
            
            // window.addEventListener('spriteIsKeyPressed', (event) => {
            //     const keyname = event.detail;  // Get the key name from Python
            //     const target = this.props.vm.runtime.targets[1];  // Get the sprite target
            //     console.log('Key pressed:', keyname);
            //     console.log('Target:', target);
                
            //     // Mock the util object to include ioQuery as expected by getKeyPressed
            //     const util = {
            //         target: target,
            //         ioQuery: (device, func, args) => {
            //             // Check if the key is pressed
            //             if (device === 'keyboard' && func === 'getKeyIsDown') {
            //                 return keyname === args[0];  // Return true if the key matches the pressed key
            //             }
            //             return false;
            //         }
            //     };
            
              // Add a keydown event listener to detect any key press by the user
                // window.addEventListener('keydown', (event) => {
                //     const keyname = event.key;  // Get the key name from the event
                //     const target = this.props.vm.runtime.targets[1];  // Get the sprite target
                //     console.log('Key pressed:', keyname);
                //     console.log('Target:', target);

                //     // Perform sprite movement based on the key pressed
                //     switch (keyname) {
                //         case 'ArrowUp':
                //             target.setDirection(0);  // Move Up (0 degrees)
                //             target.move(10);  // Move 10 steps upwards
                //             break;
                //         case 'ArrowDown':
                //             target.setDirection(180);  // Move Down (180 degrees)
                //             target.move(10);  // Move 10 steps downwards
                //             break;
                //         case 'ArrowLeft':
                //             target.setDirection(-90);  // Move Left (-90 degrees)
                //             target.move(10);  // Move 10 steps left
                //             break;
                //         case 'ArrowRight':
                //             target.setDirection(90);  // Move Right (90 degrees)
                //             target.move(10);  // Move 10 steps right
                //             break;
                //         default:
                //             // For any other key, perform a default action
                //             console.log(`Key ${keyname} pressed. No specific movement defined.`);
                //             target.move(5);  // Move a small step for any other key
                //             break;
                //     }

                //     // Redraw the stage to reflect the movement
                //     this.props.vm.runtime.requestRedraw();

                // });

            
            
    
        // window.addEventListener('spriteSwitchCostume', (event) => {
        //     const costumeName = event.detail.toLowerCase();
        //     console.log(costumeName);
            
        //     console.log(matchingCostume);
        //         const target = this.props.vm.runtime.targets[1];
        //         console.log(target);
        //         const costumeIndex = target.getCostumeIndexByName(requestedCostume.toString());

        //         const util = { target: target };
        //         this.looksBlocks.switchCostume({ COSTUME: matchingCostume }, util, costumeIndex);
        // });

        // window.addEventListener('spriteSwitchBackdrop', (event, BACKDROP) => {
        //     const backdropName = event.detail.toLowerCase();
        //     console.log(backdropName);
            
        //     let matchingbackdrop;
        //     for (let i = 0; i < backdropJson.length; i++) {
        //         if (backdropJson[i].name.toLowerCase() === backdropName) {
        //         // console.log('matching try')
        //         matchingbackdrop = backdropJson[i].assetId;
        //         break;
        //         }
        //     }
        //     console.log(matchingbackdrop);
        //     if (matchingbackdrop) {
        //         const target = this.props.vm.runtime.targets[0];
        //         // this.runtime.getTargetForStage(),
        //         const exampletarget = this.props.vm.runtime.getTargetForStage();
        //         console.log(exampletarget);
        //         console.log(target);
        //         // const util = { target: target };
        //         this.looksBlocks.switchBackdrop({ BACKDROP: BACKDROP }, exampletarget );
        //     } else {
        //         console.log(`No matching costume found for ${backdropName}`);
        //     }
        // });
    }
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.stageSize !== nextProps.stageSize ||
            this.props.isColorPicking !== nextProps.isColorPicking ||
            this.state.colorInfo !== nextState.colorInfo ||
            this.props.isFullScreen !== nextProps.isFullScreen ||
            this.state.question !== nextState.question ||
            this.props.micIndicator !== nextProps.micIndicator ||
            this.props.isStarted !== nextProps.isStarted ||
            this.props.isRealtimeMode !== nextProps.isRealtimeMode;
    }
    componentDidUpdate(prevProps) {
        if (this.props.isColorPicking && !prevProps.isColorPicking) {
            this.startColorPickingLoop();
        } else if (!this.props.isColorPicking && prevProps.isColorPicking) {
            this.stopColorPickingLoop();
        }
        this.updateRect();
        this.renderer.resize(this.rect.width, this.rect.height);
    }
    componentWillUnmount() {
        this.detachMouseEvents(this.canvas);
        this.detachRectEvents();
        this.stopColorPickingLoop();
        this.props.vm.runtime.removeListener('QUESTION', this.questionListener);
    }
    questionListener(question) {
        this.setState({ question: question });
    }
    handleQuestionAnswered(answer) {
        this.setState({ question: null }, () => {
            this.props.vm.runtime.emit('ANSWER', answer);
        });
    }
    startColorPickingLoop() {
        this.intervalId = setInterval(() => {
            if (typeof this.pickX === 'number') {
                this.setState({ colorInfo: this.getColorInfo(this.pickX, this.pickY) });
            }
        }, 30);
    }
    stopColorPickingLoop() {
        clearInterval(this.intervalId);
    }
    attachMouseEvents(canvas) {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('touchmove', this.onMouseMove);
        document.addEventListener('touchend', this.onMouseUp);
        canvas.addEventListener('mousedown', this.onMouseDown);
        canvas.addEventListener('touchstart', this.onMouseDown);
        canvas.addEventListener('wheel', this.onWheel);
    }
    detachMouseEvents(canvas) {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchmove', this.onMouseMove);
        document.removeEventListener('touchend', this.onMouseUp);
        canvas.removeEventListener('mousedown', this.onMouseDown);
        canvas.removeEventListener('touchstart', this.onMouseDown);
        canvas.removeEventListener('wheel', this.onWheel);
    }
    attachRectEvents() {
        window.addEventListener('resize', this.updateRect);
        window.addEventListener('scroll', this.updateRect);
    }
    detachRectEvents() {
        window.removeEventListener('resize', this.updateRect);
        window.removeEventListener('scroll', this.updateRect);
    }
    updateRect() {
        this.rect = this.canvas.getBoundingClientRect();
    }
    getScratchCoords(x, y) {
        const nativeSize = this.renderer.getNativeSize();
        return [
            (nativeSize[0] / this.rect.width) * (x - (this.rect.width / 2)),
            (nativeSize[1] / this.rect.height) * (y - (this.rect.height / 2))
        ];
    }
    getColorInfo(x, y) {
        return {
            x: x,
            y: y,
            ...this.renderer.extractColor(x, y, colorPickerRadius)
        };
    }
    handleDoubleClick(e) {
        const { x, y } = getEventXY(e);
        // Set editing target from cursor position, if clicking on a sprite.
        const mousePosition = [x - this.rect.left, y - this.rect.top];
        const drawableId = this.renderer.pick(mousePosition[0], mousePosition[1]);
        if (drawableId === null) return;
        const targetId = this.props.vm.getTargetIdForDrawableId(drawableId);
        if (targetId === null) return;
        this.props.vm.setEditingTarget(targetId);
    }
    onMouseMove(e) {
        const { x, y } = getEventXY(e);
        const mousePosition = [x - this.rect.left, y - this.rect.top];

        if (this.props.isColorPicking) {
            // Set the pickX/Y for the color picker loop to pick up
            this.pickX = mousePosition[0];
            this.pickY = mousePosition[1];
        }

        if (this.state.mouseDown && !this.state.isDragging) {
            const distanceFromMouseDown = Math.sqrt(
                Math.pow(mousePosition[0] - this.state.mouseDownPosition[0], 2) +
                Math.pow(mousePosition[1] - this.state.mouseDownPosition[1], 2)
            );
            if (distanceFromMouseDown > dragThreshold) {
                this.cancelMouseDownTimeout();
                this.onStartDrag(...this.state.mouseDownPosition);
            }
        }
        if (this.state.mouseDown && this.state.isDragging) {
            // Editor drag style only updates the drag canvas, does full update at the end of drag
            // Non-editor drag style just updates the sprite continuously.
            if (this.props.useEditorDragStyle) {
                this.positionDragCanvas(mousePosition[0], mousePosition[1]);
            } else {
                const spritePosition = this.getScratchCoords(mousePosition[0], mousePosition[1]);
                this.props.vm.postSpriteInfo({
                    x: spritePosition[0] + this.state.dragOffset[0],
                    y: -(spritePosition[1] + this.state.dragOffset[1]),
                    force: true
                });
            }
        }
        const coordinates = {
            x: mousePosition[0],
            y: mousePosition[1],
            canvasWidth: this.rect.width,
            canvasHeight: this.rect.height
        };
        this.props.vm.postIOData('mouse', coordinates);
    }
    onMouseUp(e) {
        const { x, y } = getEventXY(e);
        const mousePosition = [x - this.rect.left, y - this.rect.top];
        this.cancelMouseDownTimeout();
        this.setState({
            mouseDown: false,
            mouseDownPosition: null
        });
        const data = {
            isDown: false,
            x: x - this.rect.left,
            y: y - this.rect.top,
            canvasWidth: this.rect.width,
            canvasHeight: this.rect.height,
            wasDragged: this.state.isDragging
        };
        if (this.state.isDragging) {
            this.onStopDrag(mousePosition[0], mousePosition[1]);
        }
        this.props.vm.postIOData('mouse', data);

        if (this.props.isColorPicking &&
            mousePosition[0] > 0 && mousePosition[0] < this.rect.width &&
            mousePosition[1] > 0 && mousePosition[1] < this.rect.height
        ) {
            const { r, g, b } = this.state.colorInfo.color;
            const componentToString = c => {
                const hex = c.toString(16);
                return hex.length === 1 ? `0${hex}` : hex;
            };
            const colorString = `#${componentToString(r)}${componentToString(g)}${componentToString(b)}`;
            this.props.onDeactivateColorPicker(colorString);
            this.setState({ colorInfo: null });
            this.pickX = null;
            this.pickY = null;
        }
    }
    onMouseDown(e) {
        this.updateRect();
        const { x, y } = getEventXY(e);
        const mousePosition = [x - this.rect.left, y - this.rect.top];
        if (this.props.isColorPicking) {
            // Set the pickX/Y for the color picker loop to pick up
            this.pickX = mousePosition[0];
            this.pickY = mousePosition[1];
            // Immediately update the color picker info
            this.setState({ colorInfo: this.getColorInfo(this.pickX, this.pickY) });
        } else {
            if (e.button === 0 || (window.TouchEvent && e instanceof TouchEvent)) {
                this.setState({
                    mouseDown: true,
                    mouseDownPosition: mousePosition,
                    mouseDownTimeoutId: setTimeout(
                        this.onStartDrag.bind(this, mousePosition[0], mousePosition[1]),
                        400
                    )
                });
            }
            const data = {
                isDown: true,
                x: mousePosition[0],
                y: mousePosition[1],
                canvasWidth: this.rect.width,
                canvasHeight: this.rect.height
            };
            this.props.vm.postIOData('mouse', data);
            if (e.preventDefault) {
                // Prevent default to prevent touch from dragging page
                e.preventDefault();
                // But we do want any active input to be blurred
                if (document.activeElement && document.activeElement.blur) {
                    document.activeElement.blur();
                }
            }
        }
    }
    onWheel(e) {
        const data = {
            deltaX: e.deltaX,
            deltaY: e.deltaY
        };
        this.props.vm.postIOData('mouseWheel', data);
    }
    cancelMouseDownTimeout() {
        if (this.state.mouseDownTimeoutId !== null) {
            clearTimeout(this.state.mouseDownTimeoutId);
        }
        this.setState({ mouseDownTimeoutId: null });
    }
    /**
     * Initialize the position of the "dragged sprite" canvas
     * @param {DrawableExtraction} drawableData The data returned from renderer.extractDrawableScreenSpace
     * @param {number} x The x position of the initial drag event
     * @param {number} y The y position of the initial drag event
     */
    drawDragCanvas(drawableData, x, y) {
        const {
            imageData,
            x: boundsX,
            y: boundsY,
            width: boundsWidth,
            height: boundsHeight
        } = drawableData;
        this.dragCanvas.width = imageData.width;
        this.dragCanvas.height = imageData.height;
        // On high-DPI devices, the canvas size in layout-pixels is not equal to the size of the extracted data.
        this.dragCanvas.style.width = `${boundsWidth}px`;
        this.dragCanvas.style.height = `${boundsHeight}px`;

        this.dragCanvas.getContext('2d').putImageData(imageData, 0, 0);
        // Position so that pick location is at (0, 0) so that  positionDragCanvas()
        // can use translation to move to mouse position smoothly.
        this.dragCanvas.style.left = `${boundsX - x}px`;
        this.dragCanvas.style.top = `${boundsY - y}px`;
        this.dragCanvas.style.display = 'block';
    }
    clearDragCanvas() {
        this.dragCanvas.width = this.dragCanvas.height = 0;
        this.dragCanvas.style.display = 'none';
    }
    positionDragCanvas(mouseX, mouseY) {
        // mouseX/Y are relative to stage top/left, and dragCanvas is already
        // positioned so that the pick location is at (0,0).
        this.dragCanvas.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
    }
    onStartDrag(x, y) {
        if (this.state.dragId) return;
        const drawableId = this.renderer.pick(x, y);
        if (drawableId === null) return;
        const targetId = this.props.vm.getTargetIdForDrawableId(drawableId);
        if (targetId === null) return;

        const target = this.props.vm.runtime.getTargetById(targetId);

        // Do not start drag unless in editor drag mode or target is draggable
        if (!(this.props.useEditorDragStyle || target.draggable)) return;

        // Dragging always brings the target to the front
        target.goToFront();

        const [scratchMouseX, scratchMouseY] = this.getScratchCoords(x, y);
        const offsetX = target.x - scratchMouseX;
        const offsetY = -(target.y + scratchMouseY);

        this.props.vm.startDrag(targetId);
        this.setState({
            isDragging: true,
            dragId: targetId,
            dragOffset: [offsetX, offsetY]
        });
        if (this.props.useEditorDragStyle) {
            const drawableData = this.renderer.extractDrawableScreenSpace(drawableId);
            this.drawDragCanvas(drawableData, x, y);
            this.positionDragCanvas(x, y);
            this.props.vm.postSpriteInfo({ visible: false });
            this.props.vm.renderer.draw();
        }
    }
    onStopDrag(mouseX, mouseY) {
        const dragId = this.state.dragId;
        const commonStopDragActions = () => {
            this.props.vm.stopDrag(dragId);
            this.setState({
                isDragging: false,
                dragOffset: null,
                dragId: null
            });
        };
        if (this.props.useEditorDragStyle) {
            // Need to sequence these actions to prevent flickering.
            const spriteInfo = { visible: true };
            // First update the sprite position if dropped in the stage.
            if (mouseX > 0 && mouseX < this.rect.width &&
                mouseY > 0 && mouseY < this.rect.height) {
                const spritePosition = this.getScratchCoords(mouseX, mouseY);
                spriteInfo.x = spritePosition[0] + this.state.dragOffset[0];
                spriteInfo.y = -(spritePosition[1] + this.state.dragOffset[1]);
                spriteInfo.force = true;
            }
            this.props.vm.postSpriteInfo(spriteInfo);
            // Then clear the dragging canvas and stop drag (potentially slow if selecting sprite)
            this.clearDragCanvas();
            commonStopDragActions();
            this.props.vm.renderer.draw();
        } else {
            commonStopDragActions();
        }
    }
    setDragCanvas(canvas) {
        this.dragCanvas = canvas;
    }
    render() {
        const {
            vm, // eslint-disable-line no-unused-vars
            onActivateColorPicker, // eslint-disable-line no-unused-vars
            ...props
        } = this.props;
        return (
            <StageComponent
                canvas={this.canvas}
                colorInfo={this.state.colorInfo}
                dragRef={this.setDragCanvas}
                question={this.state.question}
                sayText={this.state.sayText}
                onDoubleClick={this.handleDoubleClick}
                onQuestionAnswered={this.handleQuestionAnswered}
                {...props}
            />
        );
    }
}

Stage.propTypes = {
    isColorPicking: PropTypes.bool,
    isFullScreen: PropTypes.bool.isRequired,
    isRealtimeMode: PropTypes.bool,
    isStarted: PropTypes.bool,
    micIndicator: PropTypes.bool,
    onActivateColorPicker: PropTypes.func,
    onDeactivateColorPicker: PropTypes.func,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    useEditorDragStyle: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

Stage.defaultProps = {
    useEditorDragStyle: true
};

const mapStateToProps = state => ({
    isColorPicking: state.scratchGui.colorPicker.active,
    isFullScreen: state.scratchGui.mode.isFullScreen,
    isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
    isStarted: state.scratchGui.vmStatus.started,
    micIndicator: state.scratchGui.micIndicator,
    // Do not use editor drag style in fullscreen or player mode.
    useEditorDragStyle: !(state.scratchGui.mode.isFullScreen || state.scratchGui.mode.isPlayerOnly)
});

const mapDispatchToProps = dispatch => ({
    onActivateColorPicker: () => dispatch(activateColorPicker()),
    onDeactivateColorPicker: color => dispatch(deactivateColorPicker(color))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stage);