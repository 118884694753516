// export const handleSpriteCommands = (results) => {
//     const command = results.command;

//     // Split the command into individual lines
//     const lines = command.split('\n');
//     let lineNumber = 0;
//     const commandQueue = [];
//     let isExecuted = false; // Flag to track if the command has been executed

//     const executeLine = (line) => {
//         if (line.startsWith('for') || line.startsWith('while') || line.startsWith('if')) {
//             // Handle loop or condition
//             const match = line.match(/(for|while|if) (.*)/);
//             if (match) {
//                 const type = match[1];
//                 const condition = match[2];
                
//                 if (type === 'for') {
//                     const forMatch = condition.match(/(\w+) in (.*)/);
//                     if (forMatch) {
//                         const variable = forMatch[1];
//                         const iterable = eval(forMatch[2]); // Evaluate iterable
                            
//                         for (const value of iterable) {
//                             globalThis[variable] = value;
//                             isExecuted = false; // Reset flag for each iteration
//                             const loopLines = getLoopLines(lines, lineNumber);
//                             executeLines(loopLines, lineNumber + 1);
//                         }
//                     }
//                 } else if (type === 'while') {
//                     while (eval(condition)) {
//                         isExecuted = false; // Reset flag for each iteration
//                         const loopLines = getLoopLines(lines, lineNumber);
//                         executeLines(loopLines, lineNumber + 1);
//                     }
                
//                 } else if (type === 'if'){
//                     if (eval(condition)) {
//                         isExecuted = false; // Reset flag for each iteration
//                         const ifLines = getLoopLines(lines, lineNumber);
//                         executeLines(ifLines, lineNumber + 1);
//                     }
//                 }
//             }
//         } else {
//             // Handle regular sprite commands
//             if (line.includes('sprite.') && !isExecuted) {
//                 const match = line.match(/sprite\.(\w+)\((.*)\)/);
//                 if (match) {
//                     const method = match[1];
//                     const args = match[2].split(',');

//                     // Execute sprite command only once
//                     switch (method) {
//                         case 'move':
//                             const distance = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteMove', { detail: distance }));
//                             isExecuted = true; // Sprite command executed
//                             break;
//                         case 'setsize':
//                             const size = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetsize', { detail: size }));
//                             break;
//                         case 'changesize':
//                             const newSize = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangesize', { detail: newSize }));
//                             break;
//                         case 'gotoxy':
//                             const x = parseInt(args[0]);
//                             const y = parseInt(args[1]);
//                             window.dispatchEvent(new CustomEvent('spriteGotoxy', { detail: { x, y } }));
//                             break;
//                         case 'goto':
//                             window.dispatchEvent(new CustomEvent('spriteGoto'));
//                             break;
//                         case 'setx':
//                             const newX = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetx', { detail: newX }));
//                             break;
//                         case 'sety':
//                             const newY = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSety', { detail: newY }));
//                             break;
//                         case 'changex':
//                             const deltaX = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangex', { detail: deltaX }));
//                             break;
//                         case 'changey':
//                             const deltaY = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangey', { detail: deltaY }));
//                             break;
//                         case 'setdirection':
//                             const angle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetdirection', { detail: angle }));
//                             break;
//                         case 'right':
//                             const rightAngle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteRight', { detail: rightAngle }));
//                             break;
//                         case 'left':
//                             const leftAngle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteLeft', { detail: leftAngle }));
//                             break;
//                         case 'show':
//                             window.dispatchEvent(new CustomEvent('spriteShow'));
//                             break;
//                         case 'hide':
//                             window.dispatchEvent(new CustomEvent('spriteHide'));
//                             break;
//                         case 'say':
//                             let message = args[0].trim();
//                             if (message.startsWith("'") && message.endsWith("'")) {
//                                 message = message.substring(1, message.length - 1);
//                             } else if (message.startsWith('"') && message.endsWith('"')) {
//                                 message = message.substring(1, message.length - 1);
//                             }
//                             const event = new CustomEvent('spriteSay', { detail: message });
//                             window.dispatchEvent(event);
//                             break;
//                         case 'sayforsecs':
//                             let sayforsecsMessage = args[0].trim();
//                             let sayforsecsSeconds = parseInt(args[1].trim());
//                             if (sayforsecsMessage.startsWith("'") && sayforsecsMessage.endsWith("'")) {
//                                 sayforsecsMessage = sayforsecsMessage.substring(1, sayforsecsMessage.length - 1);
//                             } else if (sayforsecsMessage.startsWith('"') && sayforsecsMessage.endsWith('"')) {
//                                 sayforsecsMessage = sayforsecsMessage.substring(1, sayforsecsMessage.length - 1);
//                             }
//                             const sayforsecsEvent = new CustomEvent('spriteSayForSecs', { detail: { message: sayforsecsMessage, seconds: sayforsecsSeconds } });
//                             window.dispatchEvent(sayforsecsEvent);
//                             break;
//                         case 'think':
//                             let thinkMessage = args[0].trim();
//                             if (thinkMessage.startsWith("'") && thinkMessage.endsWith("'")) {
//                                 thinkMessage = thinkMessage.substring(1, thinkMessage.length - 1);
//                             } else if (thinkMessage.startsWith('"') && thinkMessage.endsWith('"')) {
//                                 thinkMessage = thinkMessage.substring(1, thinkMessage.length - 1);
//                             }
//                             const thinkEvent = new CustomEvent('spriteThink', { detail: thinkMessage });
//                             window.dispatchEvent(thinkEvent);
//                             break;
//                         case 'thinkforsecs':
//                             let thinkforsecsMessage = args[0].trim();
//                             let thinkforsecsSeconds = parseInt(args[1].trim());
//                             if (thinkforsecsMessage.startsWith("'") && thinkforsecsMessage.endsWith("'")) {
//                                 thinkforsecsMessage = thinkforsecsMessage.substring(1, thinkforsecsMessage.length - 1);
//                             } else if (thinkforsecsMessage.startsWith('"') && thinkforsecsMessage.endsWith('"')) {
//                                 thinkforsecsMessage = thinkforsecsMessage.substring(1, thinkforsecsMessage.length - 1);
//                             }
//                             const thinkforsecsEvent = new CustomEvent('spriteThinkForSecs', { detail: { message: thinkforsecsMessage, seconds: thinkforsecsSeconds } });
//                             window.dispatchEvent(thinkforsecsEvent);
//                             break;
//                         case 'input':
//                             let inputMessage = args[0].trim();
//                             if (inputMessage.startsWith("'") && inputMessage.endsWith("'")) {
//                                 inputMessage = inputMessage.substring(1, inputMessage.length - 1);
//                             } else if (inputMessage.startsWith('"') && inputMessage.endsWith('"')) {
//                                 inputMessage = inputMessage.substring(1, inputMessage.length - 1);
//                             }
//                             const inputEvent = new CustomEvent('spriteInput', { detail: inputMessage });
//                             window.dispatchEvent(inputEvent);
//                             break;
//                         case 'switchcostume':
//                             let costumeName = args[0].trim();
//                             if (costumeName.startsWith("'") && costumeName.endsWith("'")) {
//                                 costumeName = costumeName.substring(1, costumeName.length - 1);
//                             } else if (costumeName.startsWith('"') && costumeName.endsWith('"')) {
//                                 costumeName = costumeName.substring(1, costumeName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSwitchCostume', { detail: costumeName }));
//                             break;
//                         case 'nextcostume':
//                             window.dispatchEvent(new CustomEvent('spriteNextCostume'));
//                             break;
//                         case 'switchbackdrop':
//                             let backdropName = args[0].trim();
//                             if (backdropName.startsWith("'") && backdropName.endsWith("'")) {
//                                 backdropName = backdropName.substring(1, backdropName.length - 1);
//                             } else if (backdropName.startsWith('"') && backdropName.endsWith('"')) {
//                                 backdropName = backdropName.substring(1, backdropName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSwitchBackdrop', { detail: backdropName }));
//                             break;
//                         case 'nextbackdrop':
//                             window.dispatchEvent(new CustomEvent('spriteNextBackdrop'));
//                             break;
//                         case 'iskeypresed':
//                             let keyName = args[0].trim();
//                             if (keyName.startsWith("'") && keyName.endsWith("'")) {
//                                 keyName = keyName.substring(1, keyName.length - 1);
//                             } else if (keyName.startsWith('"') && keyName.endsWith('"')) {
//                                 keyName = keyName.substring(1, keyName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteIsKeyPressed', { detail: keyName }));
//                             break;
//                         // Add other sprite methods here
//                         default:
//                             console.error(`Unknown sprite method: ${method}`);
//                     }
//                 }
//             }
//         }
//     };

//     const getLoopLines = (lines, startLine) => {
//         const loopLines = [];
//         for (let i = startLine + 1; i < lines.length; i++) {
//             const line = lines[i].trim();
//             if (line.startsWith('for') || line.startsWith('while') || line.startsWith('if') || line.startsWith('else')) {
//                 break;
//             }
//             loopLines.push(line);
//         }
//         return loopLines;
//     };

//     const executeLines = (lines, startLineNumber) => {
//         let delay = 0;
//         for (const line of lines) {
//             setTimeout(() => {
//                 executeLine(line);
//                 lineNumber = startLineNumber + 1;
//             }, delay);
//             delay += 1000; // 1 second delay
//         }
//     };

//     // Start execution
//     executeLines(lines, 0);
// };

export const handleSpriteCommands = (results) => {
    const command = results.command;

    // Split the command into individual lines
    const lines = command.split('\n');
    let lineNumber = 0;

    const executeLine = (line, lineNumber) => {
        if (line.startsWith('for') || line.startsWith('while') || line.startsWith('if')) {
            // Handle loop or condition
            const match = line.match(/(for|while|if) (.*)/);
            if (match) {
                const type = match[1];
                const condition = match[2];
                
                // if (type === 'for') {
                //     const forMatch = condition.match(/(\w+) in (range\((\d+)\))/);  // Match for i in range(5)
                //     console.log(forMatch);
                //     if (forMatch) {
                //         const variable = forMatch[1];
                //         const rangeLimit = parseInt(forMatch[3]);

                //         for (let value = 0; value < rangeLimit; value++) {
                //             globalThis[variable] = value;
                //             const loopLines = getLoopLines(lines, lineNumber);
                //             executeLines(loopLines, lineNumber + 1, false);
                //         }
                //     }
                // } 
                if (type === 'for') {
                    const forMatch = condition.match(/(\w+) in (.*)/);  // Match for x in range(6)
                    if (forMatch) {
                        const variable = forMatch[1];
                        const rangeOrValues = forMatch[2];
                
                        // Evaluate the range expression
                        const values = eval(rangeOrValues);
                
                        // Get the loop lines, including the else clause
                        const loopLines = getLoopLines(lines, lineNumber);
                        const elseLines = getElseLines(loopLines);
                
                        // Execute the loop
                        let loopFinishedNormally = true;
                        for (let value of values) {
                            globalThis[variable] = value;
                            executeLines(loopLines, lineNumber + 1, false);
                            if (hasBreakStatement(loopLines)) {
                                loopFinishedNormally = false;
                                break;
                            }
                        }
                
                        // Execute the else clause if the loop finished normally
                        if (loopFinishedNormally && elseLines.length > 0) {
                            executeLines(elseLines, lineNumber + loopLines.length + 1, false);
                        }
                    }
                }
                else if (type === 'while') {
                    while (eval(condition)) {
                        const loopLines = getLoopLines(lines, lineNumber);
                        executeLines(loopLines, lineNumber + 1, false);
                    }
                } else if (type === 'if') {
                    if (eval(condition)) {
                        const ifLines = getLoopLines(lines, lineNumber);
                        executeLines(ifLines, lineNumber + 1, false);
                    }
                }
            }
        } else {
            // Handle regular sprite commands
            if (line.includes('sprite.')) {
                const match = line.match(/sprite\.(\w+)\((.*)\)/);
                if (match) {
                    const method = match[1];
                    const args = match[2].split(',');
                    // Execute sprite command only once
                    switch (method) {
                        case 'move':
                            const distance = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteMove', { detail: distance }));
                            // isExecuted = true; // Sprite command executed
                            break;
                        case 'setsize':
                            const size = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSetsize', { detail: size }));
                            break;
                        case 'changesize':
                            const newSize = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteChangesize', { detail: newSize }));
                            break;
                        case 'gotoxy':
                            const x = parseInt(args[0]);
                            const y = parseInt(args[1]);
                            window.dispatchEvent(new CustomEvent('spriteGotoxy', { detail: { x, y } }));
                            break;
                        case 'goto':
                            window.dispatchEvent(new CustomEvent('spriteGoto'));
                            break;
                        case 'setx':
                            const newX = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSetx', { detail: newX }));
                            break;
                        case 'sety':
                            const newY = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSety', { detail: newY }));
                            break;
                        case 'changex':
                            const deltaX = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteChangex', { detail: deltaX }));
                            break;
                        case 'changey':
                            const deltaY = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteChangey', { detail: deltaY }));
                            break;
                        case 'setdirection':
                            const angle = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSetdirection', { detail: angle }));
                            break;
                        case 'right':
                            const rightAngle = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteRight', { detail: rightAngle }));
                            break;
                        case 'left':
                            const leftAngle = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteLeft', { detail: leftAngle }));
                            break;
                        case 'show':
                            window.dispatchEvent(new CustomEvent('spriteShow'));
                            break;
                        case 'hide':
                            window.dispatchEvent(new CustomEvent('spriteHide'));
                            break;
                        case 'say':
                            let message = args[0].trim(); // First argument is the message
                            let time = args[1] ? parseFloat(args[1]) : null; // Second argument is time (if provided)
                        
                            // Strip single or double quotes from the message if they exist
                            if (message.startsWith("'") && message.endsWith("'")) {
                                message = message.substring(1, message.length - 1);
                            } else if (message.startsWith('"') && message.endsWith('"')) {
                                message = message.substring(1, message.length - 1);
                            }
                        
                            // Dispatch the event with both message and time
                            const event = new CustomEvent('spriteSay', { detail: { message, time } });
                            window.dispatchEvent(event);
                            break;
                        case 'sayforsecs':
                            let sayforsecsMessage = args[0].trim();
                            let sayforsecsSeconds = parseInt(args[1].trim());
                            if (sayforsecsMessage.startsWith("'") && sayforsecsMessage.endsWith("'")) {
                                sayforsecsMessage = sayforsecsMessage.substring(1, sayforsecsMessage.length - 1);
                            } else if (sayforsecsMessage.startsWith('"') && sayforsecsMessage.endsWith('"')) {
                                sayforsecsMessage = sayforsecsMessage.substring(1, sayforsecsMessage.length - 1);
                            }
                            const sayforsecsEvent = new CustomEvent('spriteSayForSecs', { detail: { message: sayforsecsMessage, seconds: sayforsecsSeconds } });
                            window.dispatchEvent(sayforsecsEvent);
                            break;
                        case 'think':
                            let thinkMessage = args[0].trim();
                            if (thinkMessage.startsWith("'") && thinkMessage.endsWith("'")) {
                                thinkMessage = thinkMessage.substring(1, thinkMessage.length - 1);
                            } else if (thinkMessage.startsWith('"') && thinkMessage.endsWith('"')) {
                                thinkMessage = thinkMessage.substring(1, thinkMessage.length - 1);
                            }
                            const thinkEvent = new CustomEvent('spriteThink', { detail: thinkMessage });
                            window.dispatchEvent(thinkEvent);
                            break;
                        case 'thinkforsecs':
                            let thinkforsecsMessage = args[0].trim();
                            let thinkforsecsSeconds = parseInt(args[1].trim());
                            if (thinkforsecsMessage.startsWith("'") && thinkforsecsMessage.endsWith("'")) {
                                thinkforsecsMessage = thinkforsecsMessage.substring(1, thinkforsecsMessage.length - 1);
                            } else if (thinkforsecsMessage.startsWith('"') && thinkforsecsMessage.endsWith('"')) {
                                thinkforsecsMessage = thinkforsecsMessage.substring(1, thinkforsecsMessage.length - 1);
                            }
                            const thinkforsecsEvent = new CustomEvent('spriteThinkForSecs', { detail: { message: thinkforsecsMessage, seconds: thinkforsecsSeconds } });
                            window.dispatchEvent(thinkforsecsEvent);
                            break;
                        case 'input':
                            let inputMessage = args[0].trim();
                            if (inputMessage.startsWith("'") && inputMessage.endsWith("'")) {
                                inputMessage = inputMessage.substring(1, inputMessage.length - 1);
                            } else if (inputMessage.startsWith('"') && inputMessage.endsWith('"')) {
                                inputMessage = inputMessage.substring(1, inputMessage.length - 1);
                            }
                            const inputEvent = new CustomEvent('spriteInput', { detail: inputMessage });
                            window.dispatchEvent(inputEvent);
                            break;
                        case 'switchcostume':
                            let costumeName = args[0].trim();
                            if (costumeName.startsWith("'") && costumeName.endsWith("'")) {
                                costumeName = costumeName.substring(1, costumeName.length - 1);
                            } else if (costumeName.startsWith('"') && costumeName.endsWith('"')) {
                                costumeName = costumeName.substring(1, costumeName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteSwitchCostume', { detail: costumeName }));
                            break;
                        case 'nextcostume':
                            window.dispatchEvent(new CustomEvent('spriteNextCostume'));
                            break;
                        case 'switchbackdrop':
                            let backdropName = args[0].trim();
                            if (backdropName.startsWith("'") && backdropName.endsWith("'")) {
                                backdropName = backdropName.substring(1, backdropName.length - 1);
                            } else if (backdropName.startsWith('"') && backdropName.endsWith('"')) {
                                backdropName = backdropName.substring(1, backdropName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteSwitchBackdrop', { detail: backdropName }));
                            break;
                        case 'nextbackdrop':
                            window.dispatchEvent(new CustomEvent('spriteNextBackdrop'));
                            break;
                        case 'iskeypresed':
                            let keyName = args[0].trim();
                            if (keyName.startsWith("'") && keyName.endsWith("'")) {
                                keyName = keyName.substring(1, keyName.length - 1);
                            } else if (keyName.startsWith('"') && keyName.endsWith('"')) {
                                keyName = keyName.substring(1, keyName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteIsKeyPressed', { detail: keyName }));
                            break;
                        // Add other sprite methods here
                        default:
                            console.error(`Unknown sprite method: ${method}`);
                    }
                }
            }
        }
    };

    // Helper function to get the else lines
function getElseLines(loopLines) {
    const elseLines = [];
    let elseFound = false;
    for (const line of loopLines) {
        if (line.trim().startsWith('else')) {
            elseFound = true;
        } else if (elseFound) {
            elseLines.push(line);
        }
    }
    return elseLines;
}

// Helper function to check if a break statement is present in the loop lines
function hasBreakStatement(loopLines) {
    for (const line of loopLines) {
        if (line.trim().startsWith('break')) {
            return true;
        }
    }
    return false;
}

    const getLoopLines = (lines, startLine) => {
        const loopLines = [];
        for (let i = startLine + 1; i < lines.length; i++) {
            const line = lines[i].trim();
            if (line.startsWith('for') || line.startsWith('while') || line.startsWith('if') || line.startsWith('else')) {
                break;
            }
            loopLines.push(line);
        }
        return loopLines;
    };

    const executeLines = (lines, startLineNumber) => {
        let delay = 0;
        for (const line of lines) {
            setTimeout(() => {
                executeLine(line, startLineNumber);
                startLineNumber++;
            }, delay);
            delay += 500; // Adjust delay as needed
        }
    };

    // Start execution
    executeLines(lines, 0);
};